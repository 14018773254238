import React, {lazy, Suspense} from 'react';
import {Outlet, createBrowserRouter} from 'react-router-dom';

import {AuthGuard} from '../AuthGuard';
import {App} from '../App';
import {WaitForUser} from 'WaitForUser';
import {ContextManager} from 'contexts';
import {PageEvents} from 'shared/components/Analytics/PageEvents';
import {routes} from 'routes/Routes';
import {
    Accounts,
    Login,
    LoginLayout,
    NoAuth,
    PasswordReset,
    RequireAuth,
    SetPassword,
} from 'components';
import {Loader} from 'shared/helpers';
import TokenHandler from 'components/customer/Auth/TokenHandler';

const TDLDPreview = lazy(
    () => import('components/customer/LayoutDesigner/FullscreenTDLDPreview')
);

const BTMPreview = lazy(
    () => import('components/customer/BTM/Preview/FullscreenPreview')
);

const StandalonePreview3D = lazy(
    () => import('components/customer/Preview3D/StandalonePreview3DWrapper')
);

export const router = createBrowserRouter([
    {
        path: '/v2/*',
        element: <Outlet />,
        children: [
            {
                path: 'btm-preview',
                element: (
                    <WaitForUser allowRedirect={false}>
                        <PageEvents>
                            <Suspense fallback={<Loader loader={true} />}>
                                <BTMPreview />
                            </Suspense>
                        </PageEvents>
                    </WaitForUser>
                ),
            },
            {
                path: 'tdld-preview',
                element: (
                    <WaitForUser allowRedirect={false}>
                        <PageEvents>
                            <ContextManager>
                                <Suspense fallback={<Loader loader={true} />}>
                                    <TDLDPreview />
                                </Suspense>
                            </ContextManager>
                        </PageEvents>
                    </WaitForUser>
                ),
            },
            {
                path: 'preview-3d/*',
                element: (
                    <PageEvents>
                        <Suspense fallback={<Loader loader={true} />}>
                            <StandalonePreview3D />
                        </Suspense>
                    </PageEvents>
                ),
            },
            {
                path: '*',
                element: (
                    <WaitForUser>
                        <AuthGuard />
                    </WaitForUser>
                ),
                children: [
                    {
                        path: 'login',
                        element: (
                            <NoAuth>
                                <LoginLayout element={<Login />} />
                            </NoAuth>
                        ),
                    },
                    {
                        path: 'customer-token',
                        element: (
                            <NoAuth>
                                <TokenHandler />
                            </NoAuth>
                        ),
                    },
                    {
                        path: 'reset-password',
                        element: (
                            <NoAuth>
                                <LoginLayout element={<PasswordReset />} />
                            </NoAuth>
                        ),
                    },
                    {
                        path: 'set-password',
                        element: (
                            <NoAuth>
                                <LoginLayout element={<SetPassword />} />
                            </NoAuth>
                        ),
                    },
                    {
                        path: 'accounts',
                        element: (
                            <NoAuth>
                                <Accounts />
                            </NoAuth>
                        ),
                    },
                    {
                        path: '*',
                        element: (
                            <RequireAuth>
                                <PageEvents>
                                    <App />
                                </PageEvents>
                            </RequireAuth>
                        ),
                        children: routes,
                    },
                ],
            },
        ],
    },
]);
